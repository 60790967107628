import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Header from "../comp/Header";
import Slider, { Settings } from "react-slick";
import { useLocation } from "react-router-dom";
import NewFooter from "../comp/NewFooter";
import { Helmet } from "react-helmet";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useMediaQuery } from "../Util";
import cover from "../Image/5 (6).jpg";

enum SELECTEDBLOG {
  ALL,
  VIDEODOWNLOADER,
  FILEMANAGER,
  MUSICPLAYER,
  VIDEOTOMP3,
  GALLERY,
  RADIOMONKEY,
  OTHERS,
}

const NewBlog = () => {
  const matches = useMediaQuery("(max-width: 1024px)");
  const [selectedBlog, setSelectedBlog] = useState<SELECTEDBLOG>(
    SELECTEDBLOG.ALL
  );

  const location = useLocation();

  function SampleNextArrow(props: any) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
          display: "flex",
          height: "40px",
          width: "100px",
          zIndex: "50",
          marginTop: "-30px",
          marginRight: !matches ? "-50px" : "-10px",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={40} style={{ color: "#EA2924" }} />
      </div>
    );
  }

  function SamplePreviousArrow(props: any) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
          display: "flex",
          height: "40px",
          width: "100px",
          zIndex: "50",
          marginTop: "-30px",
          marginLeft: !matches ? "-50px" : "-10px",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={40} style={{ color: "#EA2924" }} />
      </div>
    );
  }

  var settings: Settings = {
    lazyLoad: "ondemand",
    arrows: true,
    autoplay: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePreviousArrow />,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  var categorySettings: Settings = {
    lazyLoad: "ondemand",
    arrows: true,
    dots: true,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  const fetchSearchParam = () => {
    switch (location.pathname) {
      case "/blogs":
        setSelectedBlog(SELECTEDBLOG.ALL);
        break;
      case "/blogs/video-downloader":
        console.log("Video Downloader");
        setSelectedBlog(SELECTEDBLOG.VIDEODOWNLOADER);
        break;
      case "/blogs/file-manager":
        setSelectedBlog(SELECTEDBLOG.FILEMANAGER);
        break;
      case "/blogs/music":
        setSelectedBlog(SELECTEDBLOG.MUSICPLAYER);
        break;
      case "/blogs/video-to-mp3":
        setSelectedBlog(SELECTEDBLOG.VIDEOTOMP3);
        break;
      case "/blogs/gallery":
        setSelectedBlog(SELECTEDBLOG.GALLERY);
        break;
      case "/blogs/radio-monkey":
        setSelectedBlog(SELECTEDBLOG.RADIOMONKEY);
        break;
      case "/blogs/otherblogs":
        setSelectedBlog(SELECTEDBLOG.OTHERS);
        break;
      default:
        setSelectedBlog(SELECTEDBLOG.ALL);
        break;
    }
  };

  useEffect(() => {
    fetchSearchParam();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Insightful Blogs on Tech, Trends, and More | Rareprob</title>
        <meta
          name="description"
          content="Read our informative and engaging blog posts covering the latest tech, tips of utility apps and insights to stay informed and inspired."
        />
      </Helmet>
      <main
        className=" bg-gradient-to-t bg-white h-full overflow-hidden  "
        id="start"
      >
        <Header page="blogs" color="bg-[#0a4a82]/90" />
        <div className="px-2 xl:px-20 pt-14 ">
          {/* Latest TODO */}
          <h1 className="text-2xl xl:text-4xl ml-10 xl:ml-14 mt-10 font-bold">
            Latest Blogs
          </h1>
          {/* <Lottie
            animationData={AnimationData}
            style={{
              position: "fixed",

              right: "1vh",
              zIndex: 10,
            }}
            className="top-[10%] lg:top-[11%] h-[20%] lg:h-[30%]"
          /> */}
          <div className="h-[650px] xl:h-[450px] text-black " id="blogsSlider">
            <Slider {...settings} className="blog_slider">
            <LatestBlog
                appName="Music Player"
                heading="How to Tailor Your Audio Listening Experience with the ASD Music Player"
                image="https://img.rareprob.com/img/website/blogs_rb/how-to-tailor-your-audio-listening-experience-with-the-asd-music-player-cover.webp"
                link="/blogs/how-to-tailor-your-audio-listening-experience-with-the-asd-music-player"
                text="Tailor Your Audio Listening Experience with ASD Music Player"
                date="02 December 2024"
              />
            <LatestBlog
                appName="Music Player"
                heading="Introducing the Best Video and Audio Player for Android in 2024"
                image="https://img.rareprob.com/img/website/blogs_rb/introducing-the-best-video-and-audio-player-for-android-in-2024-cover.webp"
                link="/blogs/best-video-audio-player-android-2024"
                text="Discover the best video and audio player for Android in 2024! Explore top free music apps that offer advanced features like equalizer, and offline playback."
                date="30 July 2024"
              />
            <LatestBlog
                appName="Video To MP3"
                heading="Explore The Best Powerful Online Music Players in India In 2024 Now"
                image="https://img.rareprob.com/img/website/blogs_rb/ultimate-top-6-video-to-mp3-converter-for-android-in-2024-cover.webp"
                link="/blogs/6-powerful-video-to-mp3-converter-for-android-in-2024"
                text="Discover top 6 video to MP3 converter apps for Android in 2024. Convert video to audio easily with these feature-packed converter apps. Read our blog now!"
                date="5 July 2024"
              />
              <LatestBlog
                appName="Music Player"
                heading="Explore The Best Powerful Online Music Players in India In 2024 Now"
                image="https://img.rareprob.com/img/website/blogs_rb/explore-the-best-powerful-online-music-players-in-india-in-2024-now-cover.webp"
                link="/blogs/best-offline-and-online-music-players-in-india-for-2024"
                text="Here is the list of top offline and online music players in India for 2024, according to your preferences."
                date="16 May 2024"
              />
              <LatestBlog
                appName="Video To MP3"
                heading="How to Convert & Merge Audio with ASD Video to Mp3 Converter"
                image="https://img.rareprob.com/img/website/blogs_rb/know-how-to-convert-and-merge-audio-files-with-video-to-mp3-converter-app-cover.webp"
                link="/blogs/how-to-convert-and-merge-audio-with-asd-video-to-mp3-converter"
                text="Know How To Convert And Merge Audio Files With Video To MP3 Converter App"
                date="09 May 2024"
              />
              <LatestBlog
                appName="Radio Monkey"
                heading="Everything you must know about the Best FM Radio App Now"
                image="https://img.rareprob.com/img/website/blogs_rb/everything-you-must-know-about-the-best-fm-radio-app-now-cover.webp"
                link="/blogs/explore-the-ultimate-guide-to-the-best-fm-radio-app"
                text=" Manage all your pdfs at one place with ASD File Manager. You can access PDF Reader to view, hide, move, compress, and share your files."
                date=" 26 April 2024"
              />
              <LatestBlog
                appName="File Manager"
                heading="Simplifying Your Digital Experience: The Benefits of Using a PDF Reader"
                image="https://img.rareprob.com/img/website/blogs_rb/the-benefits-of-using-a-pdf-reader-cover.webp"
                link="/blogs/benefits-of-using-pdf-reader"
                text=" Discover everything you need to know about the best FM radio for android. From features to functionality, explore how this app Modernizes your radio listening."
                date=" 16 April 2024"
              />
              <LatestBlog
                appName="Gallery"
                heading="How to Hide Photos & Videos - 5 Simple Steps to Protect Privacy"
                image="https://img.rareprob.com/img/website/blogs_rb/how-to-safeguard-your-privacy-with-the-hider-feature-in-the-gallery-app-cover.jpg"
                link="/blogs/5-simple-steps-to-hide-photos-and-videos"
                text="Looking for quick ways to hide your data on your Android Phone? Here is the step-by-step guide on how to safeguard your sensitive data effectively."
                date=" 08 April 2024"
              />
              <LatestBlog
                appName="Radio Monkey"
                heading="Check Out the FM Recorder Feature of Radio Monkey | Rareprob"
                image="https://img.rareprob.com/img/website/blogs_rb/explore-how-to-use-the-best-fm-recorder-of-radio-monkey-now-cover.jpg"
                link="/blogs/check-out-the-fm-recorder-feature-of-radio-monkey"
                text="Discover the ins and outs of Radio Monkey's best FM Recorder feature. Learn how to use this powerful tool for recording your favorite radio channels on the go."
                date=" 04 April 2024"
              />
              <LatestBlog
                appName="Video To MP3"
                heading="Explore the Amazing Features of ASD Video to Mp3 Converter"
                image="https://img.rareprob.com/img/website/blogs_rb/explore-the-most-popular-features-of-asd-video-to-mp3-converter-you-must-know-cover.jpg"
                link="/blogs/explore-the-amazing-features-of-asd-video-to-mp3-converter"
                text="Explore The Most Popular Features Of ASD Video To MP3 Converter You Must Know"
                date=" 26 Mar 2024"
              />
              <LatestBlog
                appName="Gallery"
                heading="Create text to Image with free AI Image Generator in ASD Gallery"
                image="https://img.rareprob.com/img/website/blogs_rb/how-to-create-free-ai-images-cover.jpg"
                link="/blogs/free-ai-image-generator"
                text="How to Create Free AI Images from Text in the ASD Gallery app"
                date=" 26 Mar 2024"
              />
              <LatestBlog
                appName="Video Downloader"
                heading="Introducing the Free DP Generator feature to elevate your DPs with ASD Video Downloader"
                image="https://img.rareprob.com/img/website/blogs_rb/introducing-the-free-dp-generator-feature-cover.jpg"
                link="/blogs/free-dp-generator-that-stand-out-your-profile-picture-online"
                text=" Free DP Generator that Stand Out Your Profile Picture Online - ASD
                Video Downloader"
                date=" 13 Mar 2024"
              />
              <LatestBlog
                appName="Radio Monkey"
                heading="What are the Top 3 Useful Features of Radio Monkey You Should Know"
                image="https://img.rareprob.com/img/website/blogs_rb/what-are-the-top-3-useful-features-of-radio-monkey-you-should-know-cover.jpg"
                link="/blogs/3-best-features-of-radio-monkey-you-should-know"
                text="This digital world has brought convenience to our lives yet we are always busy. This busy life also needs a break. The best way to have a nice fulfilling break is through Entertainment."
                date=" 07 Mar 2024"
              />
              <LatestBlog
                appName="Music Player"
                heading="How to convert your favourite music videos to MP3 with an All-in-One Music Player"
                image="https://img.rareprob.com/img/website/blogs_rb/how-to-convert-your-favourite-music-videos-to-mp3-with-an-all-in-one-music-player-cover.jpg"
                link="/blogs/convert-your-favourite-video-to-mp3-with-asd-music-player"
                text="In this world of technology, everything is possible. With this advancement, we have made performing any task easy and efficient. The same goes for the music player for Android."
                date="29 Feb 2024"
              />
              <LatestBlog
                appName="File Manager"
                heading="Explore Secure, Fast In-Built Browser 2024 in the ASD File Manager app"
                image="https://img.rareprob.com/img/website/blogs_rb/fast-in-built-browser-2024-cover.jpg"
                link="/blogs/fast-in-built-browser-2024-in-the-ASD-file-manager-app"
                text="
                Browser is an application that allows the user to interact and look at all the information contained in the World Wide Web be it images and videos."
                date="22 Feb 2024"
              />
              <LatestBlog
                appName="Video To MP3"
                heading="How to Convert Video to Audio Easily in Free Video to MP3 Converter"
                image="https://img.rareprob.com/img/website/blogs_rb/how-to-convert-video-to-audio-easily-in-free-video-to-mp3-converter-cover.jpg"
                link="/blogs/how-to-convert-video-to-mp3-on-any-device-5-easy-steps"
                text="We are living in an era where the media and entertainment industry is progressing. Since, the introduction of smartphones and several applications we have started using different media formats daily. We are not only sharing images, videos, and audio but also creating them."
                date="21 Feb 2024"
              />
              <LatestBlog
                appName="Radio Monkey"
                heading="How to Listen to International Live Radio Stations on Radio Monkey in 2024"
                image="https://img.rareprob.com/img/website/blogs_rb/how-to-listen-to-international-live-radio-stations-on-radio-fm-in-2024-cover.jpg"
                link="/blogs/tune-into-top-international-live-radio-fm-stations-in-2024"
                text="We all are connected digitally in every corner of the world. We get any data or information easily from any corner of the world instantly including entertainment in all forms.  "
                date="12 Feb 2024"
              />
              <LatestBlog
                appName="Music Player"
                heading="6 Best Music Player Apps in India 2024"
                image="https://img.rareprob.com/img/website/blogs_rocks/6-best-music-player-apps-in-india-2024-cover.jpg"
                link="/blogs/6-best-music-player-apps-in-india-for-2024"
                text="India is a diverse country where people of different religions and cultures live together. Different parts of India have different music. Any music streaming app that can bring this diversity into one platform is the best music player app in India. "
                date="17 Jan 2024"
              />
              <LatestBlog
                appName="Music Player"
                heading="Top 3 Best Music Players Xiaomi users must try in 2024"
                image="https://img.rareprob.com/img/website/blogs_rocks/top-3-best-music-players-xiaomi-users-must-try-in-2024-cover.jpg"
                link="/blogs/5-best-music-player-apps-for-xiaomi-users-in-2024"
                text="
                Xiaomi is a Chinese company that has been ruling the smartphone market since 2010. The company designs and manufactures electronic devices other than smartphones such as laptops, tablets, televisions, smartwatches, smartband and more."
                date="16 Jan 2024"
              />
              <LatestBlog
                appName="File Manager"
                heading="How to Zip Files and Create Zip Files in the ASD File Manager"
                image="https://img.rareprob.com/img/website/blogs_rb/how-zipper-files-revolutionize-file-compression-cover.jpg"
                link="/blogs/how-to-zip-files-and-create-zip-files-in-the-asd-file-manager"
                text="
                Zip files are a one-stop solution for many multiple things. The zip files can be used for various tasks such as file compression, file encryption, split archives, and much more."
                date="10 Jan 2024"
              />
              <LatestBlog
                appName="File Manager"
                heading="How to Password Protect Your Files & Folder in Android Device"
                image="https://img.rareprob.com/img/website/blogs_rb/how-to-password-protect-your-files-folder-in-android-device-cover.jpg"
                link="/blogs/how-to-password-protect-files-and-folders-on-android-device"
                text="
                In the era where digital security is foremost safeguarding the sensitive files and folders on your Android device can be a crucial step for protecting your privacy. Whether you want to save personal documents, important work files, or cherished photos you can easily do it as there are multiple apps. 
              "
                date="08 Jan 2024"
              />
              <LatestBlog
                appName="Video To MP3"
                heading="How to Set Ringtone with Free Video to MP3 Converter App"
                image="https://img.rareprob.com/img/website/blogs_rb/how-to-set-ringtone-with-free-video-to-mp3-converter-app-cover.jpg"
                link="/blogs/how-to-set-ringtone-with-free-asd-video-to-mp3-converter"
                text="
                We use a smartphone and we need an elegant ringtone for our device. Most of us do not use the default ringtones available on the device. We like to use personalised ringtones or rather keep the phone in silent mode. Sometimes, we just want something different and unique. 
              "
                date="03 Jan 2024"
              />
              <LatestBlog
                appName="Video To MP3"
                heading="How to use Audio Cutter with Free Video to MP3 Converter App"
                image="https://img.rareprob.com/img/website/blogs_rb/how-to-use-audio-cutter-with-free-video-to-mp3-converter-app-cover.jpg"
                link="/blogs/trim-audio-with-free-video-to-mp3-converter-app"
                text="
                Today everything is available online. Any query, any doubt and the first thing we do is Google it. We want to convert a video file to MP3, we get it done online. But we don’t have any internet connections or exhausted our data usage for the day. 
              "
                date="27 Dec 2023"
              />
              <LatestBlog
                appName="Video Downloader"
                heading="Best 2024 Apps to download videos: 3 free Options "
                image="https://img.rareprob.com/img/website/blogs_rb/best-2024-apps to-download-videos-3-free-options-cover.jpg"
                link="/blogs/best-apps-to-download-videos-2024"
                text="
                In this dynamic digital content consumption era, finding the best app to download videos can be confusing. As we step into 2024 the demand for efficient and best video downloader apps continues to rise. To simplify your work, we have curated a list of the Best 2024 Video Downloader apps to enhance your online experience.
              "
                date="27 Dec 2023"
              />
              <LatestBlog
                appName="Gallery"
                heading="Create your best Christmas memories with the All-in-one ASD Gallery App"
                image="https://img.rareprob.com/img/website/blogs_rb/all-in-one-asd-gallery-app-cover.webp"
                link="/blogs/create-your-best-christmas-memories"
                text="
                Christmas is the most magical time to share joy, love, and happiness with our friends and family. As there are Christmas vibes all over the place ASD Gallery app is ready to enhance your joy and love for Christmas more."
                date="22 Dec 2023"
              />
              <LatestBlog
                appName="Video Downloader"
                heading="How to download social media videos easily from Android "
                image="https://img.rareprob.com/img/website/blogs_rb/how-to-download-social-media-videos-easily-from-android-cover.jpg"
                link="/blogs/download-social-media-videos-easily-from-android"
                text="
                In the realm of social media, there are captivating short videos, and the desire to download and share these moments is universal. Most social interaction is on our hands on Android smartphones and the need for a seamless and straightforward method to download social media videos has increased in a short time. 
              "
                date="20 Dec 2023"
              />
              <LatestBlog
                appName="Music Player"
                heading="Best Offline Music Player Apps for Android and iOS"
                image="https://img.rareprob.com/img/website/blogs_rocks/best-offline-music-player-apps-for-android-and-ios-cover.jpg"
                link="/blogs/best-offline-music-players-for-android-iphone
                "
                text="What is Music? Music is an exquisite art form that uses the best combination of sound and rhythm to express emotions, convey ideas, and story narration. All linguistic barriers are broken down by the universal language of music. A soothing sound that calms the restless spirit is more aesthetically pleasing to the ear. A magical gift that has healing powers. "
                date=" 19 Dec 2023"
              />
              <LatestBlog
                appName="File Manager"
                heading="How to Download Whatsapp Status: 3 Ways "
                image="https://img.rareprob.com/img/website/blogs_rb/how-to-download-whatsapp-status-3-ways-cover.jpg"
                link="/blogs/how-to-download-whatsapp-status"
                text="
                WhatsApp is not that popular instant messaging app but it has become the popular social media platform. And WhatsApp status is the next popular thing after Instagram stories. It is also the quickest way to view the recent updates of our friends and family. 
              "
                date="13 Dec 2023"
              />
              <LatestBlog
                appName="Music Player"
                heading="Celebrate Christmas Vibes with the Best Music of all Year"
                image="https://img.rareprob.com/img/website/blogs_rb/celebrate-christmas-vibes-with-the-best-music-of-all-year-cover.webp"
                link="/blogs/best-christmas-music-of-all-time"
                text="As we all know, the festive season has almost approached and with that, it's Christmas song season. The main holiday spirit or enchanting ambiance of Christmas is the music that accompanies the holiday cheer. "
                date=" 12 Dec 2023"
              />

              <LatestBlog
                appName="Music Player"
                heading="Best Free MP3 Music Player for Android in 2023"
                image="https://img.rareprob.com/img/website/blogs_rb/best-free-mp3-music-player-for-android-in-2023-cover.jpg"
                link="/blogs/top-free-music-player-apps-for-android-in-2023"
                text="A beautiful quote by Martin Luther. I don’t see if we could explain the significance of music in any other better way. Now, to calm the agitations of your soul, we have music from different artists worldwide. The best thing is that music is available to us for free."
                date=" 29 Nov 2023"
              />
              <LatestBlog
                appName="Music Player"
                heading=" Best Android Music Applications in 2023"
                image="https://img.rareprob.com/img/website/blogs_rb/best-android-music-application-in-2023-cover.jpg"
                link="/blogs/best-android-offline-online-music-player-apps-2023"
                text="Music has been around for centuries. In the olden days, we used VCRs, DVDs, CDs, and cassettes to listen to music. After that, we started downloading music and storing it on our phones or memory cards. But now, the times have changed. We have apps that give us regional to international music in an instant. Amazing!"
                date=" 27 Nov 2023"
              />
              <LatestBlog
                appName="Music Player"
                heading=" Music player for Android: The 3 best apps"
                image={cover}
                link="/blogs/best-music-player-apps-android-2023"
                text="In the dynamic music world choosing the best music player for Android can be a daunting task. With the plethora of options, you may get confused but look for a music player that provides you with advanced features and the best audio experience to enjoy your favorite tunes on the go."
                date=" 9 Nov 2023"
              />
              <LatestBlog
                appName="Music Player"
                heading=" Guide to Creating the perfect Mood-Boosting playlist             "
                image="https://img.rareprob.com/img/website/blogs_rb/coverImage.jpg"
                link="/blogs/guide-to-creating-perfect-mood-boosting-playlist"
                text="
                Music is the ultimate mood booster! It has the power to impact our moods and emotions that we go through every day. Crafting that perfect playlist has become a true art form, which can transform our moods, evoke emotions, and even energize our day.
              "
                date="17 Oct 2023"
              />
              <LatestBlog
                appName="Video To Mp3 Converter"
                heading=" How to Trim Videos with In-Built Video Cutter in ASD Video to MP3 Converter               "
                image="https://img.rareprob.com/img/website/rp_webimg/coverImage.jpg"
                link="/blogs/how-to-trim-videos-with-in-built-video-cutter-in-asd-video-to-mp3-converter"
                text="
                In today's age of digital content creation videos have become important to express and communicate with others. Whether you want to capture small moments with family, create engaging content for social media, or even edit for professional projects the ability to trim videos has become an essential skill to have.
              "
                date="11 Oct 2023"
              />
              <LatestBlog
                appName="Music Player"
                heading="Know how to Add Lyrics to your Music Player through multiple methods"
                image="https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/rareprob%2Fknow-how-to-add-lyrics-to-music-player-through-multiple-methods%2Fadd_lyrics.webp?alt=media&token=8d6fb732-cfe0-4e88-b527-c81d16040a33"
                link="/blogs/know-how-to-add-lyrics-to-music-player-through-multiple-methods"
                text="In the era of smartphones, and speakers everyone is fond of listening to music it has technically become a new therapy for us. With music, you can easily convey any feelings, emotions, and messages that words can't express. "
                date="22 Sep 2023"
              />
              <LatestBlog
                appName="Video To Mp3 Converter"
                heading="Elevate your Screen Recorder feature with the ASD Video to MP3 Converter app                "
                image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/Rare_elevate-screen-recorder-asd-video-mp3-converter-app/video_to_mp3_cover.webp"
                link="/blogs/elevate-screen-recorder-asd-video-mp3-converter-app"
                text="
                We have used screen recording multiple times to capture what is happening on screen for future playback. Whether you want to watch a presentation or a project again or any tutorial and recording all these on your screen can be of multiple uses.
              "
                date="22 Aug 2023"
              />
              <LatestBlog
                appName="Music Player"
                heading="What is Bass Boost & Sound Equalizer and how can you use it?"
                image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/Rare_bass-boost-sound-equalizer-usage-guide/equalizer_cover.webp"
                link="/blogs/bass-boost-sound-equalizer-usage-guide"
                text="But with the limited volume in our phones, we don't get the same experience as our speaker provides. Therefore, with the Bass Boost equalizer feature in the ASD Music Player app, you can get the same experience as your speaker provides. "
                date="22 Aug 2023"
              />
              <LatestBlog
                appName="Gallery"
                heading="How to restore permanently lost pictures from the gallery"
                image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/how-to-restore-permanently-lost-pictures-gallery/gallery_front.webp"
                link="/blogs/how-to-restore-permanently-lost-pictures-gallery"
                text="
              Photos are an important part of everyone’s life nowadays. But there are many reasons that it may get deleted and you need to recover them. You might accidentally click on delete all photos or maybe format an SD card accidentally.  
              "
                date="31 July 2023"
              />
              <LatestBlog
                appName="File Manager"
                heading="Introducing the Exciting New Notes Feature in ASD File Manager App"
                image="https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/rareprob%2Fnotes%2Fdaily_notes_feature.webp?alt=media&token=a0d03a7a-6c61-4f88-a2fc-b7334be68b46"
                link="/blogs/introducing-exciting-new-notes-feature-asd-file-manager-app"
                text="
              Talk about a way to express your thoughts! Well, making notes is the best way to express yourself. Technology is changing and in order to keep up we need to adapt to the changes. But still, many people prefer to take notes by hand rather than typing them. 
              "
                date="11 July 2023"
              />
              <LatestBlog
                appName="ASD Gallery App"
                heading="Now Choose the Best Free Collage Maker for your Android device              "
                image="https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/rareprob%2Fcollage_cover.webp?alt=media&token=6bdd8ef6-7b3b-4b7a-bfc9-9a754e2287d7"
                link="/blogs/choose-best-free-collage-maker-android-device"
                text="
              With ASD Gallery app online collage maker with unlimited photos, you can create a customized collage that holds more value as they are the best gifts for your friends and family. 
              "
                date="11 July 2023"
              />
            </Slider>
          </div>
          <div className="h-20"></div>
          <Slider {...categorySettings} className=" p-3 lg:p-0">
            <Link
              id="blogCategorie"
              to="/blogs"
              onClick={() => setSelectedBlog(SELECTEDBLOG.ALL)}
              className={` ${
                selectedBlog === SELECTEDBLOG.ALL
                  ? "text-sm font-bold text-[#f25f13] rounded-xl  border border-black "
                  : "text-sm border-none bg-gray-300 rounded-xl  text-black font-normal"
              } text-center 
               py-2 mb-5 !w-36 xl:!w-44`}
            >
              All
            </Link>

            <Link
              to="video-downloader"
              id="blogCategorie"
              onClick={() => setSelectedBlog(SELECTEDBLOG.VIDEODOWNLOADER)}
              className={` ${
                selectedBlog === SELECTEDBLOG.VIDEODOWNLOADER
                  ? "text-sm font-bold text-[#f25f13] rounded-xl py-1 border border-black "
                  : "text-sm border-none bg-gray-300 rounded-xl py-1 text-black font-normal"
              } text-center 
              py-2 mb-5 !w-36 xl:!w-44`}
            >
              Video Downloader
            </Link>

            <Link
              to="file-manager"
              id="blogCategorie"
              onClick={() => setSelectedBlog(SELECTEDBLOG.FILEMANAGER)}
              className={` ${
                selectedBlog === SELECTEDBLOG.FILEMANAGER
                  ? "text-sm font-bold text-[#f25f13] rounded-xl py-1 border border-black "
                  : "text-sm border-none bg-gray-300 rounded-xl py-1 text-black font-normal"
              } text-center 
                py-2 mb-5 !w-36 xl:!w-44`}
            >
              File Manager
            </Link>
            <Link
              to="music"
              id="blogCategorie"
              onClick={() => setSelectedBlog(SELECTEDBLOG.MUSICPLAYER)}
              className={` ${
                selectedBlog === SELECTEDBLOG.MUSICPLAYER
                  ? "text-sm font-bold text-[#f25f13] rounded-xl py-1 border border-black "
                  : "text-sm border-none bg-gray-300 rounded-xl py-1 text-black font-normal"
              } text-center 
                py-2 mb-5 !w-36 xl:!w-44`}
            >
              Music Player{" "}
            </Link>
            <Link
              to="video-to-mp3"
              id="blogCategorie"
              onClick={() => setSelectedBlog(SELECTEDBLOG.VIDEOTOMP3)}
              className={` ${
                selectedBlog === SELECTEDBLOG.VIDEOTOMP3
                  ? "text-sm font-bold text-[#f25f13] rounded-xl py-1 border border-black "
                  : "text-sm border-none bg-gray-300 rounded-xl py-1 text-black font-normal"
              } text-center 
                py-2 mb-5 !w-36 xl:!w-44`}
            >
              Video To Mp3{" "}
            </Link>
            <Link
              to="gallery"
              id="blogCategorie"
              onClick={() => setSelectedBlog(SELECTEDBLOG.GALLERY)}
              className={` ${
                selectedBlog === SELECTEDBLOG.GALLERY
                  ? "text-sm font-bold text-[#f25f13] rounded-xl py-1 border border-black "
                  : "text-sm border-none bg-gray-300 rounded-xl py-1 text-black font-normal"
              } text-center 
                py-2 !w-36 xl:!w-44`}
            >
              Gallery
            </Link>
            <Link
              to="radio-monkey"
              id="blogCategorie"
              onClick={() => setSelectedBlog(SELECTEDBLOG.RADIOMONKEY)}
              className={` ${
                selectedBlog === SELECTEDBLOG.RADIOMONKEY
                  ? "text-sm font-bold text-[#f25f13] rounded-xl py-1 border border-black "
                  : "text-sm border-none bg-gray-300 rounded-xl py-1 text-black font-normal"
              } text-center 
                py-2 !w-36 xl:!w-44`}
            >
              Radio Monkey
            </Link>
            <Link
              to="otherblogs"
              id="blogCategorie"
              onClick={() => setSelectedBlog(SELECTEDBLOG.OTHERS)}
              className={` ${
                selectedBlog === SELECTEDBLOG.OTHERS
                  ? "text-sm font-bold text-[#f25f13] rounded-xl py-1 border border-black "
                  : "text-sm border-none bg-gray-300 rounded-xl py-1 text-black font-normal"
              } text-center 
                py-2 !w-36 xl:!w-44`}
            >
              Others{" "}
            </Link>
          </Slider>
          <div className="h-20"></div>
          <Outlet />
        </div>
      </main>
      <div className="h-32"></div>
      <NewFooter color="" page="home" />
    </>
  );
};

export default NewBlog;

interface LatestBlogType {
  image: string;
  link: string;
  appName: string;
  heading: string;
  text: string;
  date: string;
}

const LatestBlog: React.FC<LatestBlogType> = ({
  image,
  link,
  appName,
  heading,
  text,
  date,
}) => {
  return (
    <div className="w-full px-10 py-6 lg:py-10 h-[680px] xl:h-[450px] text-black !flex xl:flex-row flex-col !items-start xl:!items-center !justify-around xl:space-x-10">
      <img
        src={image}
        alt={appName}
        className="w-full lg:w-[410px] h-96  object-fill  rounded-xl"
      />

      <div className="w-full xl:w-3/5 flex flex-col items-start justify-center  space-y-10 lg:space-y-4">
        <div className="flex flex-col items-start justify-start">
          <p className="text-xs mt-2 font-semibold">{appName}</p>
          <Link to={link} className=" max-h-32">
            <h2 className="text-lg text-left xl:text-3xl hover:text-[#ef5612] cursor-pointer transition-all font-semibold ">
              {heading}
            </h2>
          </Link>
        </div>

        <p className="text-sm text-gray-700 line-clamp-2">{text}</p>
        <div className="flex flex-col space-y-3">
          <Link
            to={link}
            className="bg-[#0a4a82]/90 hover:text-[#0a4a82]  hover:bg-white rounded-xl px-12 text-lg py-3  text-white font-medium"
          >
            Read more
          </Link>
          <p className="text-xs text-gray-400">{date}</p>
        </div>
      </div>
    </div>
  );
};
