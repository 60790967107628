import Slider, { Settings } from "react-slick";
import React, { lazy, Suspense } from "react";
import Header from "../../comp/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./NewHome.css";
import { Helmet } from "react-helmet";
import Lottie from "lottie-react";
import AnimationData from "../../Image/Animation - 1700815103521.json";
// import NewAppDesign from "./NewAppDesign/NewAppDesign";
// import NewGameDesign from "./NewGameDesign/NewGameDesign";
// import NewMissionDesign from "./NewMissionDesign/NewMissionDesign";
// import NewWhoWeAre from "./NewWhoWeAre/NewWhoWeAre";
import { useRef, useState } from "react";
// import OurAchievment from "./OurAchievment/OurAchievment";
// import ExploreBlogs from "./ExploreBlogs/ExploreBlogs";
// import Testimonials from "./Testimonials/Testimonials";
import NewFooter from "../../comp/NewFooter";
import NewFaqSection from "./NewFaqSection/NewFaqSection";
// import NewGameDesignDesktop from "./NewGameDesignDesktop/NewGameDesignDesktop";
// import ExploreBlogsDesktop from "./ExploreBlogsDesktop/ExploreBlogsDesktop";
import { useMediaQuery } from "../../Util";
import FadeIn from "react-fade-in/lib/FadeIn";
const Fade = require("react-reveal/Fade");

const LazyWhoWeAre = lazy(() => import("./NewWhoWeAre/NewWhoWeAre"));
const LazNewAppDesign = lazy(() => import("./NewAppDesign/NewAppDesign"));
const LazyGameDesign = lazy(() => import("./NewGameDesign/NewGameDesign"));
const LazyGameDesignDesktop = lazy(
  () => import("./NewGameDesignDesktop/NewGameDesignDesktop")
);
const LazyMissionDesign = lazy(
  () => import("./NewMissionDesign/NewMissionDesign")
);
const LazyOurAchievment = lazy(() => import("./OurAchievment/OurAchievment"));
const LazyExploreBlogsDesktop = lazy(
  () => import("./ExploreBlogsDesktop/ExploreBlogsDesktop")
);
const LazyExploreBlogs = lazy(() => import("./ExploreBlogs/ExploreBlogs"));
const LazyTestimonials = lazy(() => import("./Testimonials/Testimonials"));

enum APPSLIDE {
  UTILITY,
  ROCKSPLAYER,
  FILEMANAGER,
}

const NewHome = () => {
  // const [silder_3, setSlider_3] = useState<boolean>(true);
  const [slider, setSlider] = useState<APPSLIDE>(APPSLIDE.UTILITY);
  const matches = useMediaQuery("(max-width: 1024px)");
  const sliderRef = useRef<Slider>(null);

  const fetchJson = () =>
    JSON.stringify({
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      name: "Rareprob Solutions Private Limited",
      image: "https://rareprob.com/images/newDesign/PHONES.webp",
      "@id": "https://rareprob.com/images/newDesign/PHONES.webp",
      url: "https://rareprob.com/",
      telephone: "82793 23413",
      address: {
        "@type": "PostalAddress",
        streetAddress:
          "Suite 1305, second floor, Building 1000, Doon business express park, opposite Transport Nagar, Subhash Nagar",
        addressLocality: "Dehradun",
        postalCode: "248001",
        addressCountry: "IN",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: 30.2817743,
        longitude: 77.9885862,
      },
      openingHoursSpecification: {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        opens: "09:00",
        closes: "20:00",
      },
      sameAs: [
        "https://www.facebook.com/rareprob",
        "https://twitter.com/rareprob",
        "https://www.instagram.com/rareprob_/",
        "https://www.youtube.com/channel/UCNXZXijMtxueoWaNiye73AQ",
        "https://www.linkedin.com/company/rareprob/",
        "https://in.pinterest.com/rareprob/",
        "https://rareprob.com/",
      ],
    });

  const fetchJson2 = () =>
    JSON.stringify({
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "What are the Utility apps?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The utility apps allow users to meet their basic digital tasks with the most advanced features and exceptional user-friendly interface, which is far better than your device’s default apps. These Utility apps are regularly updated to perform multiple functionalities effortlessly.",
          },
        },
        {
          "@type": "Question",
          name: "What is ASD Dev?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "ASD Dev stands for ASD Dev Video Player for All Format is the developer's account of Rareprob Solution on the Play Store. You can visit the Rareprob website and ASD Dev's app on Playstore where you can explore all the apps.",
          },
        },
        {
          "@type": "Question",
          name: "Why use the ASD Dev app?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The ASD Dev is made of some of the finest software engineers in our country who develops mobile app to provide you with multiple features and functionalities such as you can download your favorite videos and images from any social media platform, video hider, managing downloads, multiple language support, watching and listening to high-quality media content, managing your digital documents and much more.",
          },
        },
        {
          "@type": "Question",
          name: "What does ASD Dev have to offer?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "ASD Dev app offers you multiple daily utility Android applications such as ASD Rocks Video Player, ASD File Manager, ASD Video Downloader, ASD Gallery app, ASD Music Player, and many more.",
          },
        },
      ],
    });

  var settings: Settings = {
    dots: true,
    autoplay: true,
    speed: 600,
    slidesToShow: 1,
    centerPadding: "0px",
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: false,
    autoplaySpeed: 5000,
    arrows: false,
    infinite: true,
    centerMode: false,
    fade: false,
    pauseOnDotsHover: true,
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Explore Our Utility Mobile Apps for Every Need | Rareprob</title>
        <link rel="canonical" href="https://rareprob.com" />
        <meta
          name="description"
          content="Discover a range of utility mobile apps designed to enhance productivity, entertainment, and convenience on various platforms."
        />
        <meta
          property="og:title"
          content="Explore Our Utility Mobile Apps for Every Need | Rareprob"
        />
        <meta property="og:site_name" content="RareProb Solutions" />
        <meta property="og:url" content="https://rareprob.com/" />
        <meta
          property="og:description"
          content="Discover a range of utility mobile apps designed to enhance productivity, entertainment, and convenience on various platforms."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://rareprob.com/images/homepage/web%20page.webp"
        ></meta>
        <script type="application/ld+json">{fetchJson()}</script>
        <script type="application/ld+json">{fetchJson2()}</script>
      </Helmet>

      <Header color="bg-gray-700/20" page="home" />

      <main
        className="main_container"
        style={{ overflowX: "hidden", position: "relative" }}
        id="start"
      >
        {/* <Lottie
          animationData={AnimationData}
          style={{
            position: "fixed",
            height: "30%",
            top: "8vh",
            left: "1vh",
            zIndex: 1,
          }}
        /> */}
        {/* <Lottie
          animationData={AnimationData}
          style={{
            position: "fixed",
            height: "30%",
            top: "8vh",
            right: "1vh",
            zIndex: 1000,
          }}
        /> */}
        <Slider
          ref={sliderRef}
          fade={true}
          waitForAnimate={false}
          {...settings}
          className={`main_container_slider ${
            slider === APPSLIDE.UTILITY ? "active" : ""
          }`}
          afterChange={(currentSlide) => {
            switch (currentSlide) {
              case 0:
                setSlider(APPSLIDE.UTILITY);
                break;
              case 1:
                setSlider(APPSLIDE.ROCKSPLAYER);
                break;
              case 2:
                setSlider(APPSLIDE.FILEMANAGER);
                break;
              default:
                setSlider(APPSLIDE.UTILITY);
                break;
            }
          }}
        >
          <div className="slider_3">
            <div className="slider_3_banner"></div>
            <div className="slider_3_desktop">
              <FadeIn delay={500}>
                <div className="slider_3_desktop_text">
                  <div>
                    <h1 className="everything">Everything You Need, </h1>
                    <h2 className="create">
                      {" "}
                      <span> </span>We Create
                    </h2>
                  </div>
                  <h4>
                    More than 12+ Best-in-Class <br /> Utility Apps for Android
                    and iPhones
                  </h4>
                  <a
                    href="https://play.google.com/store/apps/dev?id=6259831402052261779&hl=en-IN"
                    target={"_blank"}
                    rel="noreferrer nofollow"
                  >
                    <img
                      src="/images/homepage/googleImg.png"
                      alt="Rocks Video Player | Play Store"
                      className="google"
                    />
                  </a>

                  <div className="slider_3_powered">
                    <p>Powered by </p>
                    <h3>ASD DEV</h3>
                  </div>
                </div>
              </FadeIn>

              <img
                src="https://img.rareprob.com/img/website/rp_webimg/first%20screen%20image.webp"
                alt="Rocks PLayer"
                className="slider_1_main_image"
              />
            </div>
            <div className="slider_3_mobile">
              <div className="slider_3_weCreate">
                <div>
                  <h1 className="everything_mobile">Everything You </h1>
                  <h2 className="create_mobile">
                    {" "}
                    <span>Need, </span>We Create
                  </h2>
                </div>
              </div>
              <h4>
                More than <span>12+ Best-in-Class Utility Apps</span> for
                Android and iPhones
              </h4>

              <Fade big duration={2000}>
                <img
                  src="https://img.rareprob.com/img/website/rp_webimg/first%20screen%20image.webp"
                  alt="Rocks PLayer"
                  className="slider_1_main_image"
                />
              </Fade>

              <div className="slider_3_powered">
                <p>Powered by </p>
                <h3>ASD DEV</h3>
              </div>
              <a
                href="https://play.google.com/store/apps/dev?id=6259831402052261779&hl=en-IN"
                target={"_blank"}
                rel="noreferrer nofollow"
              >
                <img
                  style={{ width: "130px", height: "40px", marginTop: "10px" }}
                  src="/images/homepage/google.webp"
                  alt="Rocks Video Player | Play Store"
                  className="google"
                />
              </a>
            </div>
          </div>
          <div className="slider_1">
            <img
              src="/images/newDesign/diamond_shape_2.webp"
              className="slider_1_second_shape"
              alt="Rocks Shape"
            />
            <img
              src="/images/newDesign/diamond_shape.webp"
              alt="Rocks Shape"
              className="slider_1_shape1"
            />

            {/* {slider === APPSLIDE.ROCKSPLAYER && ( */}
            {/* // <Fade > */}
            <div className="slider_1_rocks_logo">
              <div>
                <h2>
                  R{" "}
                  <img
                    style={{
                      display: "inline",
                      objectFit: "contain",
                      height: !matches ? "50px" : "35px",
                      width: !matches ? "40px" : "30px",
                      objectPosition: "top",
                      marginLeft: "-4px",
                    }}
                    src="/images/icons/videoplayer.png"
                    alt="Rocks Video Player"
                  />
                </h2>{" "}
                <h2 style={{ marginLeft: "-15px" }}>CKS PLAYER</h2>
              </div>

              <h3>HD VIDEO PLAYER</h3>
              <p className="slider_1_rocks_logo_text">
                Fast and easy to use video player <br /> which support all video
                formats effortlesly
              </p>
              <div className="slider_1_downloads">
                <button>
                  <h3>50M+</h3>
                  <p style={{ marginTop: "-4px" }}>Downloads</p>
                </button>

                <a
                  href="https://tinyurl.com/rocksplayer"
                  rel="noreferrer nofollow"
                  target={"_blank"}
                >
                  <img
                    src="/images/homepage/google.webp"
                    className="google_play"
                    alt="Google Play Store | Rocks Video Player"
                  />
                </a>
              </div>
            </div>

            <Fade>
              <img
                src="https://img.rareprob.com/img/website/rp_webimg/find%20dif%20%E2%80%93%201.webp"
                alt="Rocks PLayer"
                className="slider_1_main_image"
              />
            </Fade>
          </div>

          <div className="slider_2">
            <div className="slider_2_text">
              <div className="slider_2_icon">
                <img
                  src="/images/icons/filemanagerIcon.webp"
                  alt="File Manager | Rareprob"
                />
                <h2 style={{ color: "#3b3f60" }}>File Manager</h2>
              </div>
              <h4 style={{ color: "#3b3f60" }}>One App Multiple Features</h4>
              <div className="slider_2_download_desktop">
                <div className="first">
                  <p style={{ marginTop: "5px", marginRight: "10px" }}>Over</p>
                  <h5>5M+ </h5>
                  <p style={{ marginTop: "5px", marginLeft: "10px" }}>
                    Downloads
                  </p>
                </div>
                <a
                  href="https://tinyurl.com/rareprob-File-Manager"
                  rel="noreferrer nofollow"
                  target={"_blank"}
                >
                  <img
                    src="/images/homepage/google.webp"
                    alt="File Manager Google Play Store | Rareprob"
                    className="google"
                  />
                </a>
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <img
                style={{
                  position: "absolute",
                  zIndex: "10",
                  width: "500px",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 20,
                  margin: !matches
                    ? "auto -20px auto auto "
                    : "auto -10px auto auto ",
                }}
                src="/images/newDesign/circles.webp"
                alt="Images Banner File Manager"
                className="File_shape"
              />
              <img
                style={{
                  position: "relative",
                  zIndex: "20",
                }}
                src="https://img.rareprob.com/img/website/rp_webimg/fime%20manager%20banner.webp"
                alt="File Manager | Rareprob"
                className="slider_2_main_image"
              />
            </div>

            <div className="slider_2_download">
              <div className="first">
                <h5>5M+ </h5>
                <p>Downloads</p>
              </div>
              <a
                href="https://tinyurl.com/rareprob-File-Manager"
                rel="noreferrer"
                target={"_blank"}
              >
                <img
                  src="/images/homepage/google.webp"
                  alt="File Manager Google Play Store | Rareprob"
                />
              </a>
            </div>
          </div>
        </Slider>
        <Suspense
          fallback={
            <div className="flex items-center justify-center">
              {" "}
              loading please wait...
            </div>
          }
        >
          <LazNewAppDesign />
        </Suspense>

        <div style={{ height: "10vh" }} className="gameHeight"></div>
        <Suspense
          fallback={
            <div className="flex items-center justify-center">
              {" "}
              loading please wait...
            </div>
          }
        >
          <div className="game_mobile">
            <LazyGameDesign />
          </div>
        </Suspense>
        <Suspense
          fallback={
            <div className="flex items-center justify-center">
              {" "}
              loading please wait...
            </div>
          }
        >
          <div className="game_mobile_desktop">
            <LazyGameDesignDesktop />
          </div>
        </Suspense>
        <Suspense
          fallback={
            <div className="flex items-center justify-center">
              {" "}
              loading please wait...
            </div>
          }
        >
          <LazyMissionDesign />
        </Suspense>

        <Suspense
          fallback={
            <div className="flex items-center justify-center">
              {" "}
              loading please wait...
            </div>
          }
        >
          <LazyWhoWeAre />
        </Suspense>
        <Suspense
          fallback={
            <div className="flex items-center justify-center">
              {" "}
              loading please wait...
            </div>
          }
        >
          <LazyOurAchievment />
        </Suspense>

        {!matches ? (
          <Suspense
            fallback={
              <div className="flex items-center justify-center">
                {" "}
                loading please wait...
              </div>
            }
          >
            <LazyExploreBlogsDesktop />
          </Suspense>
        ) : (
          <Suspense
            fallback={
              <div className="flex items-center justify-center">
                {" "}
                loading please wait...
              </div>
            }
          >
            <LazyExploreBlogs />
          </Suspense>
        )}

        <Suspense
          fallback={
            <div className="flex items-center justify-center">
              {" "}
              loading please wait...
            </div>
          }
        >
          <LazyTestimonials />
        </Suspense>

        <NewFaqSection />
      </main>
      <NewFooter color="" page="home" />
    </>
  );
};

export default NewHome;
