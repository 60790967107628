import React from "react";
import { Link } from "react-router-dom";
import cover from "../Image/5 (6).jpg";
interface BlogContainerType {
  name: string;
  image: string;
  text: string;
  path: string;
  logo: string;
  altz: string;
  date: string;
}

const BlogContainer: React.FC<BlogContainerType> = ({
  name,
  image,
  text,
  path,
  logo,
  altz,
  date,
}) => {
  return (
    <Link
      to={path}
      className="bg-gray-200  w-[300px] xl:w-[280px] h-[510px]  px-4  py-3  rounded-3xl"
    >
      <div className="flex items-center justify-start">
        {logo !== "" && <img src={logo} alt="gallery icon" className="w-8" />}
        <h1 className="text-sm md:text-lg font-semibold ml-4">{name}</h1>
      </div>
      <div className="">
        <img
          loading={"lazy"}
          src={image}
          alt={altz}
          className="w-full md:w-full h-60 object-cover lg:object-fill rounded-xl mt-6"
        />
        <p className="mt-2 px-4 text-gray-600  inline-block  bg-opacity-50 ">
          {date}
        </p>
        <h1 className=" md:w-full text-sm md:text-[14px] font-semibold h-20 mt-3 text-center">
          {text}
        </h1>
        <button className=" rounded-lg mb-4  mx-auto items-center flex mt-0 bg-[#0a4a82]/90 hover:bg-white hover:text-[#0a4a82] text-white font-medium  px-4 py-2">
          Read more
        </button>
      </div>
    </Link>
  );
};

const NewBlogDesign = () => {
  return (
    <div>
      <div className="w-[90%] mx-auto place-items-center grid lg:grid-cols-3 xl:grid-cols-4 grid-col-1 gap-10 md:gap-28">
      <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-tailor-your-audio-listening-experience-with-the-asd-music-player-cover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/how-to-tailor-your-audio-listening-experience-with-the-asd-music-player"
          date="02 December 2024"
          text="Tailor Your Audio Listening Experience with ASD Music Player"
        />
      <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/introducing-the-best-video-and-audio-player-for-android-in-2024-cover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-video-audio-player-android-2024"
          date="30 July 2024"
          text="Introducing the Best Video and Audio Player for Android in 2024"
        />
    
      <BlogContainer
          name={"Video To MP3"}
          altz="Video To MP3"
          image="https://img.rareprob.com/img/website/blogs_rb/ultimate-top-6-video-to-mp3-converter-for-android-in-2024-cover.webp"
          logo="/images/icons/vmp3.png"
          path="/blogs/6-powerful-video-to-mp3-converter-for-android-in-2024"
          date="05 July 2024"
          text="Discover top 6 video to MP3 converter apps for Android in 2024."
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/explore-the-best-powerful-online-music-players-in-india-in-2024-now-cover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-offline-and-online-music-players-in-india-for-2024"
          date="16 May 2024"
          text="Explore The Best Powerful Online Music Players in India In 2024 Now"
        />
        <BlogContainer
          name={"Video To MP3"}
          altz="Video To Mp3 Converter"
          image="https://img.rareprob.com/img/website/blogs_rb/know-how-to-convert-and-merge-audio-files-with-video-to-mp3-converter-app-cover.webp"
          logo="/images/icons/vmp3.png"
          path="/blogs/how-to-convert-and-merge-audio-with-asd-video-to-mp3-converter"
          date="09 May 2024"
          text=" Learn about its intuitive features for format conversion, audio merging, etc."
        />
        <BlogContainer
          name={"Radio Monkey"}
          altz="Radio Monkey "
          image="https://img.rareprob.com/img/website/blogs_rb/everything-you-must-know-about-the-best-fm-radio-app-now-cover.webp"
          logo="/images/icons/radioLauncher.png"
          path="/blogs/explore-the-ultimate-guide-to-the-best-fm-radio-app"
          date="26 April 2024"
          text="Discover everything you need to know about the best FM radio for android."
        />
        <BlogContainer
          name={"File Manager "}
          altz="File Manager "
          image="https://img.rareprob.com/img/website/blogs_rb/the-benefits-of-using-a-pdf-reader-cover.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/benefits-of-using-pdf-reader"
          date="16 April 2024"
          text="Simplifying Your Digital Experience: The Benefits of Using a PDF Reader"
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-safeguard-your-privacy-with-the-hider-feature-in-the-gallery-app-cover.jpg"
          logo="/images/icons/gallery.png"
          path="/blogs/5-simple-steps-to-hide-photos-and-videos"
          date="08 April 2024"
          text="Looking for quick ways to hide your data on your Android Phone? Here is the step-by-step guide. 
          "
        />
        <BlogContainer
          name={"Radio Monkey"}
          altz="Radio Monkey"
          image="https://img.rareprob.com/img/website/blogs_rb/explore-how-to-use-the-best-fm-recorder-of-radio-monkey-now-cover.jpg"
          logo="/images/icons/radioLauncher.png"
          path="/blogs/check-out-the-fm-recorder-feature-of-radio-monkey"
          date="04 April 2024"
          text="Explore How To Use the best FM Recorder of Radio Monkey Now
          "
        />
        <BlogContainer
          name={"Video To MP3"}
          altz="Video To Mp3 Converter"
          image="https://img.rareprob.com/img/website/blogs_rb/explore-the-most-popular-features-of-asd-video-to-mp3-converter-you-must-know-cover.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/explore-the-amazing-features-of-asd-video-to-mp3-converter"
          date="26 Mar 2024"
          text="Explore The Most Popular Features Of ASD Video To MP3 Converter You Must Know"
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-create-free-ai-images-cover.jpg"
          logo="/images/icons/gallery.png"
          path="/blogs/free-ai-image-generator"
          date="26 Mar 2024"
          text="How to Create Free AI Images from Text in the ASD Gallery app"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs_rb/introducing-the-free-dp-generator-feature-cover.jpg"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/free-dp-generator-that-stand-out-your-profile-picture-online"
          date="13 Mar 2024"
          text="Introducing the Free DP Generator feature to elevate your DPs with ASD Video Downloader"
        />

        <BlogContainer
          name={"Radio Monkey"}
          altz="Radio Monkey"
          image="https://img.rareprob.com/img/website/blogs_rb/what-are-the-top-3-useful-features-of-radio-monkey-you-should-know-cover.jpg"
          logo="/images/icons/radioLauncher.png"
          path="/blogs/3-best-features-of-radio-monkey-you-should-know
          "
          date="07 Mar 2024"
          text="What are the Top 3 Useful Features of Radio Monkey You Should Know"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-convert-your-favourite-music-videos-to-mp3-with-an-all-in-one-music-player-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/convert-your-favourite-video-to-mp3-with-asd-music-player"
          date="29 Feb 2024"
          text="How to convert your favourite music videos to MP3 with an All-in-One Music Player"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/fast-in-built-browser-2024-cover.jpg"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/fast-in-built-browser-2024-in-the-ASD-file-manager-app"
          date="22 Feb 2024"
          text="Explore Secure, Fast In-Built Browser 2024 in the ASD File Manager app"
        />
        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="21 Feb 2024"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-convert-video-to-audio-easily-in-free-video-to-mp3-converter-cover.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/how-to-convert-video-to-mp3-on-any-device-5-easy-steps"
          text="How to Convert Video to Audio Easily in Free Video to MP3 Converter"
        />
        <BlogContainer
          name={"Radio Monkey"}
          altz="Radio Monkey"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-listen-to-international-live-radio-stations-on-radio-fm-in-2024-cover.jpg"
          logo="/images/icons/radioLauncher.png"
          path="/blogs/tune-into-top-international-live-radio-fm-stations-in-2024
          "
          date="12 feb 2024"
          text="How to Listen to International Live Radio Stations on Radio Monkey in 2024"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rocks/6-best-music-player-apps-in-india-2024-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/6-best-music-player-apps-in-india-for-2024
          "
          date="17 Jan 2024"
          text="6 Best Music Player Apps in India 2024"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rocks/top-3-best-music-players-xiaomi-users-must-try-in-2024-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/5-best-music-player-apps-for-xiaomi-users-in-2024
          "
          date="16 Jan 2024"
          text="Top 3 Best Music Players Xiaomi users must try in 2024"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/how-zipper-files-revolutionize-file-compression-cover.jpg"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/how-to-zip-files-and-create-zip-files-in-the-asd-file-manager"
          date="10 Jan 2024"
          text="How to Zip Files and Create Zip Files in the ASD File Manager"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-password-protect-your-files-folder-in-android-device-cover.jpg"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/how-to-password-protect-files-and-folders-on-android-device"
          date="08 Jan 2024"
          text="How to Password Protect Your Files & Folder in Android Device"
        />
        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="03 Jan 2024"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-set-ringtone-with-free-video-to-mp3-converter-app-cover.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/how-to-set-ringtone-with-free-asd-video-to-mp3-converter"
          text="How to Set Ringtone with Free Video to MP3 Converter App
          "
        />
        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="27 Dec 2023"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-use-audio-cutter-with-free-video-to-mp3-converter-app-cover.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/trim-audio-with-free-video-to-mp3-converter-app"
          text="How to use Audio Cutter with Free Video to MP3 Converter App
          "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs_rb/best-2024-apps to-download-videos-3-free-options-cover.jpg"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/best-apps-to-download-videos-2024"
          date="27 Dec 2023"
          text="Best 2024 Apps to download videos: 3 free Options "
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://img.rareprob.com/img/website/blogs_rb/all-in-one-asd-gallery-app-cover.webp"
          path="/blogs/create-your-best-christmas-memories"
          logo="/images/icons/gallery.png"
          date="22 Dec 2023"
          text="
          Create your best Christmas memories with the All-in-one ASD Gallery App
          "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-download-social-media-videos-easily-from-android-cover.jpg"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-social-media-videos-easily-from-android"
          date="20 Dec 2023"
          text="How to download social media videos easily from Android "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rocks/best-offline-music-player-apps-for-android-and-ios-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-offline-music-players-for-android-iphone
          "
          date="19 Dec 2023"
          text="Best Offline Music Player Apps for Android and iOS"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-download-whatsapp-status-3-ways-cover.jpg"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/how-to-download-whatsapp-status"
          date="13 Dec 2023"
          text="How to Download Whatsapp Status: 3 Ways"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/celebrate-christmas-vibes-with-the-best-music-of-all-year-cover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-christmas-music-of-all-time"
          date="12 Dec 2023"
          text="Celebrate Christmas Vibes with the Best Music of all Year"
        />

        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/best-free-mp3-music-player-for-android-in-2023-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/top-free-music-player-apps-for-android-in-2023"
          date="29 Nov 2023"
          text="Best Free MP3 Music Player for Android in 2023"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/best-android-music-application-in-2023-cover.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-android-offline-online-music-player-apps-2023"
          date="27 Nov 2023"
          text="Best Android Music Applications in 2023"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image={cover}
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-music-player-apps-android-2023"
          date="9 Nov 2023"
          text="Music player for Android: The 3 best apps
          "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs_rb/coverImage.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/guide-to-creating-perfect-mood-boosting-playlist"
          date="17 Oct 2023"
          text="Guide to Creating the Perfect Mood-Boosting Playlist
          "
        />
        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="11 Oct 2023"
          image="https://img.rareprob.com/img/website/rp_webimg/coverImage.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/how-to-trim-videos-with-in-built-video-cutter-in-asd-video-to-mp3-converter"
          text="How to Trim Videos with In-Built Video Cutter in ASD Video to MP3 Converter  "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/rareprob%2Fknow-how-to-add-lyrics-to-music-player-through-multiple-methods%2Fadd_lyrics.webp?alt=media&token=8d6fb732-cfe0-4e88-b527-c81d16040a33"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/know-how-to-add-lyrics-to-music-player-through-multiple-methods"
          date="22 Sep 2023"
          text="Know how to Add Lyrics to your Music Player through multiple methods
          "
        />
        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="22 Aug 2023"
          image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/Rare_elevate-screen-recorder-asd-video-mp3-converter-app/video_to_mp3_cover.webp"
          logo="/images/icons/vmp3.png"
          path="/blogs/elevate-screen-recorder-asd-video-mp3-converter-app"
          text="Elevate your Screen Recorder feature with the ASD Video to MP3 Converter app   "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/Rare_bass-boost-sound-equalizer-usage-guide/equalizer_cover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/bass-boost-sound-equalizer-usage-guide"
          date="22 Aug 2023"
          text="What is Bass Boost & Sound Equalizer and how can you use it?
          "
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/how-to-restore-permanently-lost-pictures-gallery/gallery_front.webp"
          path="/blogs/how-to-restore-permanently-lost-pictures-gallery"
          logo="/images/icons/gallery.png"
          date="31 July 2023"
          text="
          How to restore permanently lost pictures from the gallery
          "
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/rareprob%2Fcollage_cover.webp?alt=media&token=6bdd8ef6-7b3b-4b7a-bfc9-9a754e2287d7"
          path="/blogs/choose-best-free-collage-maker-android-device"
          logo="/images/icons/gallery.png"
          date="11 July 2023"
          text="
          Now Choose the Best Free Collage Maker for your Android device

          "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://firebasestorage.googleapis.com/v0/b/rareprob-website.appspot.com/o/rareprob%2Fnotes%2Fdaily_notes_feature.webp?alt=media&token=a0d03a7a-6c61-4f88-a2fc-b7334be68b46"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/introducing-exciting-new-notes-feature-asd-file-manager-app"
          date="11 July 2023"
          text="Introducing the Exciting New Notes Feature in ASD File Manager App"
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://img.rareprob.com/img/website/blogs/gallery/asd_Gallery/cover_.webp"
          logo="/images/icons/gallery.png"
          path="/blogs/why-asd-gallery-app-better-than-default-gallery-apps"
          date="2 July 2023"
          text="Why ASD Gallery App is Better than Default Gallery Apps"
        />
        <BlogContainer
          name={"Father's Day"}
          altz="Father's Day"
          date="15 Jun 2023"
          image="https://img.rareprob.com/img/website/blogs/christmas/father-day/happy-father-s-day-greeting-card-design-with-dotted-bow-tie-typography-letter_1314-2772.webp"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/lets-find-out-how-why-when-fathers-day-celebrated"
          text="
          Let's Find Out How, Why & When Fathers' Day is Celebrated
          "
        />
        <BlogContainer
          name={"Gallery"}
          altz="Gallery"
          image="https://img.rareprob.com/img/website/blogs/gallery/rare-GalleryCorrectionCover.webp"
          logo="/images/icons/gallery.png"
          path="/blogs/how-to-use-color-correction-to-improve-images"
          date="12 Jun 2023"
          text="
          Discover how to use colour correction effectively to improve your photos.
          "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/filemanager-new/rarefile-cover.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/top-5-ways-to-choose-right-file-manager-app"
          date="7 Jun 2023"
          text="Top 5 ways to choose the right file managing app for your device"
        />

        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/repost/rareVd-repostCover.jpg"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/how-to-repost-on-instagram-using-asd-video-downloader"
          date="7 Jun 2023"
          text="How to Repost on Instagram using the ASD Video Dowloader app"
        />

        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/dailymotion/videoDownloader-dailymotionCover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-dailymotion-videos-free"
          date="24 May 2023"
          text="How ASD Video Downloader app is the best choice to download Dailymotion videos "
        />

        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/filemanager-new/rare-file-manage-docs.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/9-effective-tips-to-manage-your-files-on-phone"
          date="24 May 2023"
          text="9 Tips to Effectively Manage Your Documents with the ASD File Manager App"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/download/Artboard%2011%20(5)%20(1).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-vimeo-videos"
          date="18 May 2023"
          text="How to download Vimeo Video with the ASD Video Downloader app"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/download/rare-tiktok-front.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-tiktok-videos-for-free"
          date="11 May 2023"
          text="Enjoy TikTok videos offline anytime and anywhere with the ASD Video Downloader."
        />

        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/download/rare-twitter.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/Twitter-Video-Downloader"
          date="11 May 2023"
          text="Find the best way to download Twitter videos with the ASD Video Downloader."
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/download/rare-media-videos.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/photo-downloader-for-social-media-platforms"
          date="9 May 2023"
          text="How to download photos from different platforms using a free Photo Downloader"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/download/rare-asd-videoDownloader.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/Safe-Instagram-Video-Downloader"
          date="9 May 2023"
          text="What are the 3 safest ways to download Instagram videos with ASD Video Downloader?"
        />
        <BlogContainer
          name="Gallery"
          altz="Photo Editor & Collage Maker with Photo Vault"
          date="3 April 2023"
          image="https://img.rareprob.com/img/website/blogs/gallery/tenGalleryFeatures/galleryCover.webp"
          logo="/images/icons/gallery.png"
          path="/blogs/best-photo-editing-app-asd-gallery"
          text="Top 10 features of the best photo editing app to enhance your photos | ASD Gallery App"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/RelaxingSounds/Relax%20and%20Meditate%20with%20the%20best%20of%20relaxing%20Sounds_%20Calm%20Sleep%20Music_1.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/relax-meditate-best-relaxing-sounds-calm-sleep-music"
          date="26 March 2023"
          text="Relax and Meditate with the best of relaxing Sounds : Calm Sleep Music            "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/videoDownloaderFourFeatures/rare-videoAllFormatCover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-social-media-videos"
          date="17 March 2023"
          text="Top 4 Reasons to download social media videos from ASD Video Downloader"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/seven/rare-fileSecureCover.webp"
          logo="/images/icons/file mana.webp.png"
          path="/blogs/7-reasons-file-management-app-must-have-device"
          date="17  March  2023"
          text=" 7 Reasons why a File Management app is a must-have for your device           "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/sixFeatures/cover image.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/top-6-features-music-player-app"
          date="17 March 2023"
          text="Explore top 6 features you will love about ASD Rocks Music App              "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/instagram-reel/cover image (1).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/ways-to-download-instagram-reels-fast"
          date="5 March 2023"
          text=" Best 3 ways to download Instagram reels with fast downloader"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/music-lover/cover image.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/music-lovers-explore-best-music-player-app-android-device"
          date="5 March 2023"
          text=" Music Lovers! Explore the Best Music Player App for Your Android Device"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/what-is-file-encryption-cover.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/secure-your-private-files-with-file-encryption"
          date="5  March  2023"
          text="What is File Encryption, its Importance, and How Does it Secure Your Private Files?"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/rare-FileWebBroeserCover-min.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/discover-why-the-web-browser-of-the-asd-file-manager-app-is-like-no-other-app"
          date="26 Feb  2023"
          text=" Discover Why the Web Browser of the ASD File Manager app is like no other app!"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/rare-FacebookDCover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/easy-way-to-download-facebook-videos"
          date="26 Feb  2023"
          text=" Top 3 ways to download Facebook videos with fast Facebook video downloader"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/new/carModeCover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-car-mode-music-app"
          date="26 Feb 2023"
          text=" Best car mode music app for an exciting & musical road trip "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-manage-large-files-cover.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/manage-large-files-easily-asd-file-manager-guide"
          date="19 Feb  2023"
          text=" How to Manage Large Files on Android Devices with File Manager"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/rVideoDCover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/download-videos-with-video-detection"
          date="19 Feb  2023"
          text=" How does Video detection help in ASD Video Downloader?"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/new/rEqualizerCover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/equalizer-better-sound-quality"
          date="19 Feb 2023"
          text=" How to manage Equalizer to play better sound quality Music?"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/rare-fileCover.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/Shortcut-and-Hide-Unhide-files"
          date="13 Feb  2023"
          text=" What are the Two Most Important Functions A File Manager App Should Have | ASD File Manager "
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/new/rare-musicdesignThemes.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/customized-themes"
          date="12 Feb 2023"
          text=" Style up the music app with beautiful and customised themes"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/rare-videoDHCover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/manage-bookmarks-and-Downloads"
          date="12 Feb  2023"
          text=" Manage bookmarks and downloads easily with Video Downloader"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/cover (4).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/manage-storage"
          date="5 Feb  2023"
          text=" Manage the storage, free up files space with Video Downloader 2023"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/SleepTimerBlog-cover.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/sleep-timer"
          date="5 Feb 2023"
          text="How does a sleep timer help to enjoy Online and Offline Music? "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/Rareprob-scanningFront.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/benefits-of-scanning-documents"
          date="5 Feb  2023"
          text=" What are the Benefits of Scanning Documents and How you Can Easily Do It| ASD File Manager"
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/how-to-share-files-offline-cover.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/share-files-from-android-device-to-pc"
          date="5 Feb  2023"
          text="How to transfer files from your Android Device to PC offline"
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/new/videoDownloader-cover.webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/secure-your-private-videos-using-video-downloader"
          date="28 Jan  2023"
          text=" Best locker to keep the private files safe with password protection"
        />
        <BlogContainer
          name={"Music Player"}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/main page1.webp"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/music-player-for-all-type-of-audio-and-video-formats"
          date="26 Jan  2023"
          text=" Free Android app to play Music with audio and video Formats "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager"
          image="https://img.rareprob.com/img/website/blogs/fileManager/clean-master-cover.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/remove-junk-files-using-clean-master"
          date="26 Jan  2023"
          text=" Boost Your Phone’s Performance with Clean Master | File Manager "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader"
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/cover (1).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/insta-video-downloader"
          date="19 Jan  2023"
          text="Save videos with free insta video downloader "
        />
        <BlogContainer
          name={"File Manager"}
          altz="File Manager "
          image="https://img.rareprob.com/img/website/blogs/fileManager/1200_1200 cover.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/hide-photos-videos-and-files"
          date="14 Jan  2023"
          text="Secure Your Private Files in a Secret Folder with File Manager "
        />
        <BlogContainer
          name={"Video Downloader"}
          altz="Video Downloader "
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/Artboard 2 (5).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/browser"
          date="8 Jan  2023"
          text="Built-in Browser for Faster Downloads | Video Downloader"
        />

        <BlogContainer
          name={"File Manager"}
          altz="New Year 2023 "
          image="https://img.rareprob.com/img/website/blogs/fileManager/cover (2).webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/Digital-documents"
          date="5 Jan  2023"
          text="Manage your Digital Documents with The File Manager App "
        />
        <BlogContainer
          name={"New Year 2023"}
          altz="New Year 2023 "
          image="https://img.rareprob.com/img/website/blogs/games/GAME COVER IMAGE.webp"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/fresh-free-online-games"
          date="31 Dec 2022"
          text="Fresh Fun starts with free fun Online Games | New Year 2023"
        />

        <BlogContainer
          name={"Video Downloader "}
          altz="Video Downloader "
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/Artboard 1 (4).webp"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/how-to-download-social-media-photos"
          date="30 Dec 2022"
          text="Download social media photos on your device | Video Downloader "
        />

        <BlogContainer
          name={"File Manager "}
          altz="File Manager "
          image="https://img.rareprob.com/img/website/blogs_rb/what-are-zip-files-cover.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/zip-files"
          date="30 Dec 2022"
          text="What are Zip Files and How to Create with the File Manager app?"
        />

        <BlogContainer
          name={"Video Downloader "}
          altz="Video Downloader "
          image="https://img.rareprob.com/img/website/blogs/videoDownloader/front  image.jpg"
          logo="/images/icons/videoDownloader.webp"
          path="/blogs/online-video-downloader"
          date="22 Dec 2022"
          text="Watch your favourite social media videos offline | Video Downloader "
        />

        <BlogContainer
          name={"Christmas "}
          altz="Christmas "
          image="https://img.rareprob.com/img/website/blogs/christmas/frontchris.jpg"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/glitz-up-with-christmas-diy-decor-ideas"
          date="20 Dec 2022"
          text=" Glitz up your festive mood with DIY Decoration and Gifts Ideas"
        />
        <BlogContainer
          name={"File Manager "}
          altz="File Manager "
          image="https://img.rareprob.com/img/website/blogs/fileManager/blog 2.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/benefits-of-the-file-manager-app-for-android-device"
          date="16 Dec 2022"
          text=" Why do you need a File Manager App and which one is The Best?"
        />

        <BlogContainer
          name={"Music Player "}
          altz="Music Player"
          image="https://img.rareprob.com/img/website/blogs/music/music thmb.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/best-music-player-App-for-android"
          date="9 Dec 2022"
          text="All you need to know about Best Music Player App | Music Rocks "
        />

        <BlogContainer
          name={"Christmas "}
          altz="Christmas"
          image="https://img.rareprob.com/img/website/blogs/christmas/Artboard 5 copy.jpg"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/Celebrate-Christmas-on-a-Budget"
          date="2 Dec 2022"
          text="How to Celebrate Christmas 2022 on Budget?"
        />

        <BlogContainer
          name={"Christmas "}
          altz="Christmas"
          image="https://img.rareprob.com/img/website/blogs/christmas/front image.jpg"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/apps-for-christmas-celebration"
          date="1 Dec 2022"
          text="Top 5 Android Apps to make this Christmas Season Jolly!"
        />

        <BlogContainer
          name={"Christmas "}
          altz="Christmas"
          image="https://img.rareprob.com/img/website/blogs/christmas/Artboard 1 (8).jpg"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/christmas-activities-traditions"
          date="27 Nov 2022"
          text="16 Things to do For Christmas 2022 & Traditions to Start!"
        />

        <BlogContainer
          name={"Tweedle "}
          altz=" Tweedle"
          image="https://img.rareprob.com/img/website/blogs/tweedle/tweedle.jpg"
          logo="/images/icons/appIcon/Tweedle.webp"
          path="/blogs/download-ringtone-maker-app-android-device"
          date="16 Nov 2022"
          text="Discover free prominent ringtones for your Android Device"
        />

        <BlogContainer
          name={"Games"}
          altz="Games"
          image="https://img.rareprob.com/img/website/blogs/games/game.webp"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/free-online-fun-games"
          date="16 Nov 2022"
          text="Free Online Fun games: Stack ball, Find Difference and TIC TAC TOE"
        />

        <BlogContainer
          name={"File Manager"}
          altz=" File Manager"
          image="https://img.rareprob.com/img/website/blogs_rb/what-is-asd-file-manager-cover.webp"
          logo="/images/icons/filemanagerIcon.webp"
          path="/blogs/best-file-manager-app-for-android"
          date="12 Nov 2022"
          text="What is ASD File Manager and Explore its advanced features"
        />

        <BlogContainer
          name={"Halloween Special"}
          altz="Rareprob : Halloween Special"
          date="30 Oct 2022"
          image="https://img.rareprob.com/img/website/blogs/christmas/Artboard 00000001144.jpg"
          logo="/images/rrp_rrp logo.png"
          path="/blogs/top-3-Android-apps-to-make-your-Halloween-more-special"
          text="Make this Halloween more special with music and perfect photos."
        />

        <BlogContainer
          name={"Music Player"}
          altz="Music Player for all types of audio & video formats"
          image="https://img.rareprob.com/img/website/blogs/music/music-front.jpg"
          logo="/images/icons/musicIcon.webp"
          path="/blogs/free-android-mp3-player-app"
          text="Step up your music experience with Music Rocks"
          date="30 Jan 2022"
        />

        <BlogContainer
          name="Gallery"
          altz="Photo Editor & Collage Maker with Photo Vault"
          date="1 Dec 2021"
          image="https://img.rareprob.com/img/website/blogs/gallery-front.jpg"
          logo="/images/icons/gallery.png"
          path="/blogs/gallery-photo-editor-collage-maker-app"
          text="Make your photos look picture perfect with Gallery"
        />

        <BlogContainer
          name="Video To MP3"
          altz="Video To Mp3 Converter"
          date="1 Nov 2021"
          image="https://img.rareprob.com/img/default/RareProbImg/imagesRare/video_to_mp3cover_.jpg"
          logo="/images/icons/vmp3.png"
          path="/blogs/video-to-mp3-converter-for-android-mobile-app"
          text="Easy to use Video to Mp3 converter for your Android device"
        />
        <BlogContainer
          name="Radio Monkey"
          altz="Radio Monkey"
          date="20 Oct 2021"
          image="https://img.rareprob.com/img/website/blogs/radio-front.jpg"
          logo="/images/icons/radioLauncher.png"
          path="/blogs/radio-monkey-country-specific-online-fm-app"
          text="Radio Monkey: Connect to the next-gen of Online tuners"
        />
      </div>
    </div>
  );
};

export default NewBlogDesign;
